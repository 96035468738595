<template>
  <div class="tw-bg-main p-5" style="height: 100vh">
    <ul class="list fw-bolder">
      <li>客服人數: 369</li>
      <li>註冊總人數: {{ dataFor20221205.length }}</li>
      <li>訂閱人數: {{ sub }}</li>
      <li>試用期: {{ free - sub }}</li>
    </ul>
  </div>
</template>

<script>
import jsonData from './a20221205.json'

export default {
  data() {
    return {
      dataFor20221205: [],
      free: 0, // 試用期
      sub: 12, // 已訂閱
      // * fb api
      appId: 1448302585998734,
      secret: "c6e5431a6eec7b2d1118a2c7ea12ccb5",
      version: "v16.0",
      api: `https://graph.facebook.com/${this.data.version}`,
      oauth: {
        accessToken: `${this.data.api}/oauth/access_token`,
        clientCode: `${this.data.api}/oauth/client_code`,
      },
      shortToken: "EAAUlOTkqbY4BAIWuOGDUdj9YQrYHf0H2D06lPBCZBG8s7owkIcOu06gUMGuvifFGG97VWgCWZAVOjZBBJkT8OlurRGsTcDzylVkpI9GQHmSSdqbezPyluyCnCyWLayknxaSiCc3cWsaZA8wp6Sp9c8NTZASW2cGL1NU7yRtnOBBDI7TJZAZAHygc3cvVmT3dLmqYo1Iuuys1JioVxZAKVQpq",
    }
  },
  created() {
    // this.dataFor20221205 = jsonData
    this.sortoutData()
  },
  methods: {
    sortoutData() {
      const now = new Date() * 1
      this.dataFor20221205.forEach(item => {
        // 試用期
        if (item.expirationTime >= now) this.free ++
      })
    },
  },
}
</script>